// This file is to extract uuids of mention user form MentionInput component

import { Feedback, FeedbackComment, ResponseFeedback, ResponseFeedbackComment } from '~/types/collaboration_feedbacks'

export function extractUuidsFromModelValue(modelValue: string): string[] {
  const parser = new DOMParser()
  const doc = parser.parseFromString(modelValue, 'text/html')
  const mentionElements = doc.querySelectorAll('.mention[data-uuid]')

  const uuids = Array.from(mentionElements)
    .map(element => element.getAttribute('data-uuid') || '')
    .filter(uuid => uuid)

  const uniqueUuids = Array.from(new Set(uuids))

  return uniqueUuids
}

export function convertMentionsToObjectFormat(mentions?: string[]) {
  return (
    mentions?.map(item => ({
      uuid: item,
      notify_mention: false,
    })) ?? []
  )
}

export function convertResponseFeedbackToFeedback(responseFeedback: ResponseFeedback): Feedback {
  return {
    ...responseFeedback,
    mentions: convertMentionsToObjectFormat(responseFeedback.mentions),
    comments: responseFeedback.comments?.map(comment => ({
      ...comment,
      mentions: convertMentionsToObjectFormat(comment.mentions),
      auth: comment.auth || null,
    })),
  }
}

export function convertResponseCommentToComment(responseComment: ResponseFeedbackComment): FeedbackComment {
  return {
    ...responseComment,
    mentions: convertMentionsToObjectFormat(responseComment.mentions),
  }
}
